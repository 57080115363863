import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Container, Stack } from '@tymate/margaret';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import { Section as RawSection, Separator } from 'ui';
import { TitleContainer } from 'ui/containers';
import aboutUsBackground from '../assets/images/aboutus-bg.png';
import doodleLarge from '../assets/images/doodleLarge.png';
import Content from 'components/Content';
import agnes from '../assets/images/teammates/agnes.png';
import cannelle from '../assets/images/teammates/cannelle.jpg';
import jeremy from '../assets/images/teammates/jeremy.jpg';
import lucas from '../assets/images/teammates/lucas.png';
import victor from '../assets/images/teammates/victor.jpg';
import ArticleTalkAboutUsCardsCarousel from '../components/ArticleTalkAboutUsCardsCarousel';
import RightArrowSlider from '../assets/images/RightArrowSlider.svg';
import LeftArrowSlider from '../assets/images/LeftArrowSlider.svg';
import Slider from 'react-slick';
import { Button, media } from '../ui';
import icon from '../assets/images/favicon.png';
import { CONTACT_US } from 'components/UtilModal';
import UtilsContext from 'contexts/util';
import { Link } from '@reach/router';
import ChallengesPaper from '../assets/images/feelingo-challenge-paper.jpeg';
import HeroHeader from '../components/HeroHeader';

const ContentWrapper = styled.div`
  p,
  strong {
    ${({ theme }) => theme.fontStyles.bodyLarger}
    letter-spacing: 0.6px;
    line-height: 32px;
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }

  strong {
    color: ${({ theme }) => theme.secondary};
    font-weight: normal;
  }
`;

const Section = styled(RawSection)`
  padding-left: 0;
  padding-right: 0;
`;

const CarouselContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;

  ${media.tablet`
    padding-left: ${({ theme }) => theme.spacing()};
    padding-right: ${({ theme }) => theme.spacing()};
  `}
`;

const Carousel = styled.div`
  width: calc(100% - 36px);
  margin-left: auto;
  margin-right: auto;

  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 36px;
      height: 36px;
      z-index: 30;
      margin-top: 32px;
    }

    .slick-list {
      margin-right: ${props => (props.isBanner ? 0 : '45px')};
      margin-left: ${props => (props.isBanner ? 0 : '45px')};
    }

    ${media.tablet`
    .slick-list {
      margin-right: ${props => (props.isBanner ? 0 : '64px')};
      margin-left: ${props => (props.isBanner ? 0 : '64px')};
    }
  `}

    .slick-next {
      right: 10px;

      ${media.tablet`
        right: 25px;
      `}
    }

    .slick-prev {
      left: 10px;

      ${media.tablet`
        left: 25px;
      `}
    }
  }
`;

const Description = styled.p`
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  ${({ theme }) => theme.fontStyles.bodyLarger}
  font-weight: 600;
  letter-spacing: 0.6px;
  line-height: 32px;
  text-align: center;
`;

const TeammatePhoto = styled.img`
  display: block;
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
`;

const TeammateName = styled.h3`
  ${({ theme }) => theme.fontStyles.h3}
  font-weight: 400;
`;

const TeammateRole = styled.div`
  ${({ theme }) => theme.fontStyles.bodyLarger}
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  // initialSlide: 1,
  autoplay: false,
  arrows: true,
  nextArrow: <img src={RightArrowSlider} alt="" />,
  prevArrow: <img src={LeftArrowSlider} alt="" />,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        dots: false,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        dots: false,
      },
    },
  ],
};

const Teammate = ({ name, role, photo, description }) => {
  return (
    <Stack direction="column" alignX="center" gutterSize={0.5}>
      <TeammatePhoto src={photo} />
      <TeammateName>{name}</TeammateName>
      <TeammateRole>{role}</TeammateRole>
      <div>{description}</div>
    </Stack>
  );
};

const theyTalkAboutUs = [
  {
    publishedAt: new Date('11/19/20'),
    displayName: "Merci L'écho touristique",
    excerpt: '"Feelingo une nouvelle plateforme française d\'hôtels durables"',
    picture: {
      imagePath:
        'https://www.lechotouristique.com/wp-content/uploads/2020/11/echo-site-logo-x2.png',
    },
    thematic: {
      displayName: "Merci L'écho touristique",
    },
    link:
      'https://www.lechotouristique.com/article/feelingo-une-nouvelle-plateforme-francaise-dhotels-durables',
  },
  {
    publishedAt: new Date('11/19/20'),
    displayName: 'Merci Hoteleconews',
    excerpt: '"Feelingo permet de réserver en ligne des hôtels durables"',
    picture: {
      imagePath: 'http://www.hotelseconews.com/IMG/siteon0.png?1490954397',
    },
    thematic: {
      displayName: "Merci Hoteleconews",
    },
    link: 'http://www.hotelseconews.com/Feelingo-permet-de-reserver-en.html',
  },
  {
    publishedAt: new Date('11/18/20'),
    displayName: 'Merci TourMag',
    excerpt:
      '"Feelingo une nouvelle plateforme de réservation d\'hôtels durables"',
    picture: {
      imagePath:
        'https://www.tourmag.com/photo/art/grande/25802876-26885136.jpg?v=1537797439',
    },
    thematic: {
      displayName: "Merci TourMag",
    },
    link:
      'https://www.tourmag.com/Feelingo-une-nouvelle-plateforme-de-reservations-d-hotels-durables_a106088.html',
  },
  {
    publishedAt: new Date('11/7/20'),
    displayName: 'Merci La Voix du Nord',
    excerpt:
      '"Feelingo promeut des nuits éco-compatibles dans des hôtels de toute l\'Europe"',
    picture: {
      imagePath:
        'https://lvdneng.rosselcdn.net/sites/all/themes/enabootstrap_vdn_premium/images/logo-carre-plein.svg#logo',
    },
    thematic: {
      displayName: "Merci La Voix du Nord",
    },
    link:
      'https://www.lavoixdunord.fr/890122/article/2020-11-07/feelingo-promeut-les-nuits-ecolo-compatibles-dans-des-hotels-de-toute-l-europe',
  },
  {
    publishedAt: new Date('10/22/20'),
    displayName: 'Merci Challenges',
    excerpt: 'Interview avec Pierre Henri de Menthon \nChronique "Double Jeu"',
    picture: {
      imagePath: ChallengesPaper,
    },
    thematic: {
      displayName: "Merci Challenges",
    },
    link:
      'https://www.dropbox.com/s/rd6iepwnvi5dyxo/Presse-challenges.jpg?dl=0 ',
  },
  {
    publishedAt: new Date('10/16/20'),
    displayName: 'Merci Europe 1',
    excerpt: 'Emission "La France Bouge" \nLes trophées Europe 1',
    picture: {
      imagePath:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e8/Europe_1_logo_%282010%29.svg/1200px-Europe_1_logo_%282010%29.svg.png',
    },
    thematic: {
      displayName: "Merci Europe 1",
    },
    link:
      'https://www.europe1.fr/emissions/la-france-bouge-academie/feelingo-un-site-de-reservation-dhotels-durables-qui-souhaite-repenser-la-reservation-dhebergements-en-ligne-pour-la-rendre-plus-humaine-plus-durable-et-plus-transparente-3999051',
  },
  {
    publishedAt: new Date('10/10/20'),
    displayName: "Encore merci B Smart 2 (à partir de 20'55)",
    excerpt:
      'Emission Smart Stories \nFeelingo, vers un tourisme durable \nIntervention à partir de la 21ème minute.',
    picture: {
      imagePath:
        'https://www.presse-citron.net/wordpress_prod/wp-content/uploads/2020/06/EaFVXLNX0AAdJZ9.jpg',
    },
    thematic: {
      displayName: "Merci B Smart 2 (à partir de 20'55)",
    },
    link:
      'https://www.bsmart.fr/video/820-smart-stories-emission-10-octobre-2020',
  },
  {
    publishedAt: new Date('9/8/2020'),
    displayName: "Merci B Smart 1 (à partir de 28'38)",
    excerpt:
      'Emission Smart Job \n Hôtellerie, restauration, une addition salée ? \n Intervention à partir de la 28ème minute.',
    picture: {
      imagePath:
        'https://www.presse-citron.net/wordpress_prod/wp-content/uploads/2020/06/EaFVXLNX0AAdJZ9.jpg',
    },
    thematic: {
      displayName: "Merci B Smart 1 (à partir de 28'38)",
    },
    link:
      'https://www.bsmart.fr/video/483-smart-job-emission-08-septembre-2020',
  },
];

const AboutUs = () => {
  const { t } = useTranslation();
  const { onUtilKindChange } = useContext(UtilsContext);

  return (
    <Layout>
      <SEO title={t('pageTitle.aboutUs')} />
      <HeroHeader
        title={t('header.us')}
        image={aboutUsBackground}
      />
      <Section centered>
        <Container style={{ width: '60em' }}>
          <Separator style={{ marginBottom: '50px' }}>
            <img src={icon} alt="" />
            <Description>{t('aboutUs.description')}</Description>
          </Separator>
          <ContentWrapper>
            <Content markdown={t('aboutUs.content')} />
          </ContentWrapper>
          <Button
            style={{
              margin: '0 auto',
              height: '52px',
              width: '236px',
              fontSize: '16px',
            }}
            as={Link}
            to={'/manifeste'}
            variant="secondary"
          >
            {t('aboutUs.button')}
          </Button>
        </Container>
      </Section>
      <Section centered style={{ marginBottom: '32px' }}>
        <CarouselContainer>
          <TitleContainer bold>{t('aboutUs.team')}</TitleContainer>
          <Carousel>
            <Slider
              {...settings}
              style={{ marginLeft: -16, marginRight: -16, overflow: 'visible' }}
            >
              <Teammate
                name={t('aboutUs.mates.agnes.name')}
                role={t('aboutUs.mates.agnes.role')}
                description={t('aboutUs.mates.agnes.description')}
                photo={agnes}
              />
              <Teammate
                name={t('aboutUs.mates.jeremy.name')}
                role={t('aboutUs.mates.jeremy.role')}
                description={t('aboutUs.mates.jeremy.descritpion')}
                photo={jeremy}
              />
              <Teammate
                name={t('aboutUs.mates.cannelle.name')}
                role={t('aboutUs.mates.cannelle.role')}
                description={t('aboutUs.mates.cannelle.descritpion')}
                photo={cannelle}
              />
              <Teammate
                name={t('aboutUs.mates.lucas.name')}
                role={t('aboutUs.mates.lucas.role')}
                description={t('aboutUs.mates.lucas.description')}
                photo={lucas}
              />
              <Teammate
                name={t('aboutUs.mates.victor.name')}
                role={t('aboutUs.mates.victor.role')}
                description={t('aboutUs.mates.victor.description')}
                photo={victor}
              />
            </Slider>
          </Carousel>
        </CarouselContainer>
      </Section>
      <Section centered background={doodleLarge}>
        <CarouselContainer>
          <TitleContainer bold>{t('aboutUs.talk')}</TitleContainer>
          <ArticleTalkAboutUsCardsCarousel isLinkExternal articles={theyTalkAboutUs} />
        </CarouselContainer>
      </Section>
      <Section centered>
        <Container style={{ width: '768px', paddingBottom: '40px' }}>
          <ContentWrapper>
            <Content markdown={t('aboutUs.subSection')} />
          </ContentWrapper>

          <Button
            style={{ margin: '0 auto', height: '52px', fontSize: '16px' }}
            variant="secondary"
            onClick={() => onUtilKindChange(CONTACT_US)}
          >
            {t('aboutUs.contact')}
          </Button>
        </Container>
      </Section>
    </Layout>
  );
};

export default AboutUs;

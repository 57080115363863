import React, { useContext } from 'react';
import styled from 'styled-components';
import { ArticleTalkAboutUsCard } from 'ui/cards';
import { Link } from 'gatsby';
import { media } from 'ui';
import PageContext from 'contexts/page';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RightArrowSlider from 'images/RightArrowSlider.svg';
import LeftArrowSlider from 'images/LeftArrowSlider.svg';
import { useTranslation } from 'react-i18next';

const ArticleLink = styled(Link)`
  text-decoration: none;
  outline: none;
`;

const Carousel = styled.div`
  width: calc(100% - 36px);
  margin-left: auto;
  margin-right: auto;

  .slick-slider {
    .slick-track {
      padding-bottom: ${({ theme }) => theme.spacing(2)};
    }
    .slick-next,
    .slick-prev {
      width: 36px;
      height: 36px;
      z-index: 30;
      margin-top: 32px;
    }

    .slick-list {
      margin-right: ${props => (props.isBanner ? 0 : '45px')};
      margin-left: ${props => (props.isBanner ? 0 : '45px')};
    }

    ${media.tablet`
    .slick-list {
      margin-right: ${props => (props.isBanner ? 0 : '64px')};
      margin-left: ${props => (props.isBanner ? 0 : '64px')};
    }
  `}

    .slick-next {
      right: 10px;

      ${media.tablet`
        right: 25px;
      `}
    }

    .slick-prev {
      left: 10px;

      ${media.tablet`
        left: 25px;
      `}
    }
  }
`;

const ArticleCardsCarousel = ({ articles, isLinkExternal }) => {
  const { getLanguagePrefix } = useContext(PageContext);
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: articles && articles.length < 4 ? articles.length : 3,
    slidesToScroll: 3,
    initialSlide: 1,
    autoplay: false,
    arrows: true,
    nextArrow: <img src={RightArrowSlider} alt="" />,
    prevArrow: <img src={LeftArrowSlider} alt="" />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: articles && articles.length < 3 ? articles.length : 2,
          slidesToScroll: 2,
          autoplay: false,
          dots: false,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <Carousel>
        <Slider
          {...settings}
          style={{ marginLeft: -16, marginRight: -16, overflow: 'visible' }}
        >
          {(articles || []).map((article, index) => (
            <ArticleLink
              key={index}
              as={isLinkExternal ? 'a' : Link}
              href={article?.link}
              to={
                isLinkExternal
                  ? undefined
                  : getLanguagePrefix(`${t('url.articles')}/${article.slug}`)
              }
              rel={isLinkExternal ? 'nofollow' : undefined}
            >
              <ArticleTalkAboutUsCard {...article} isCarousel external={isLinkExternal} />
            </ArticleLink>
          ))}
        </Slider>
      </Carousel>
    </>
  );
};

export default ArticleCardsCarousel;
